<div class="container-fluid pt-5 login-page">
    <div class="row">
        <div class="col-md-12">
            <div class="logo">
                <img class="logo-img" src="assets/images/logos/om-analytic.png" alt="Logo OM Analytic">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-5 col-12">
            <div class="authentication-form">
                <h2 class="fs-1">Connexion</h2>
                <div class="inner-content">
                    <div class="alert alert-danger" *ngIf="authenticationFailed"><span>Login/Mot de passe incorrect</span></div>
                    <form (ngSubmit)="onSubmit()" #loginForm="ngForm" autocomplete="off">
                        <div class="mb-3 form-group form-check form-switch custom__switch">
                            <input class="form-check-input" name="isAd" [(ngModel)]="data.isAd" type="checkbox" role="switch" id="ldapSwitchCheckbox">
                            <label class="form-check-label" for="ldapSwitchCheckbox">Connexion par LDAP ?</label>
                        </div>
                        <div class="mb-3 form-group">
                            <label for="email" class="form-label">Identifiez-vous</label>
                            <input type="email" [(ngModel)]="data.username" name="username" class="form-control form-input" placeholder="Identifiant*" id="email" aria-describedby="emailHelp" required>
                            <div id="emailHelp" class="form-text d-none">We'll never share your email with anyone else.</div>
                        </div>
                        <div class="mb-3 form-group">
                            <label for="password" class="form-label">Mot de passe</label>
                            <input type="password" [(ngModel)]="data.password" name="password" class="form-control form-input" placeholder="Mot de passe*" autocomplete="new-password" id="password" required>
                        </div>
                        <div class="mb-3 form-check d-none">
                            <input type="checkbox" class="form-check-input" id="remember-check">
                            <label class="form-check-label" for="remember-check">Rester connecté</label>
                        </div>
                        <div class="text-center">
                            <button
                              type="submit"
                              class="btn btn-primary btn-login"
                              [disabled]="!loginForm.form.valid || loginInProgress"
                            >
                              {{ authenticationText() }}
                              @if(loginInProgress){
                              <div class="spinner-border" role="status">
                                <span class="visually-hidden">Chargement...</span>
                              </div>
                              }
                            </button>
                        </div>

                    </form>
                </div>
            </div>
        </div>
        <div class="col-md-7 col-12">
            <div class="illustration text-center">
                <img src="assets/images/login-1.png" class="img-fluid" style="max-height: 600px;" alt="">
            </div>
        </div>
    </div>
    <ngx-ui-loader [loaderId]="'page'" fgsColor="#ff7900" pbColor="#ff7900"></ngx-ui-loader>
</div>
