import { Injectable } from '@angular/core';
import {RestClientService} from "../rest-client.service";
import {catchError, lastValueFrom, map, of} from "rxjs";
import {CookieNameList, CookieService} from "../cookie/cookie.service";

@Injectable({
  providedIn: 'root'
})
export class PublicKeyService {
  private modulus: string | null = null;
  private exponent: string | null = null;
  constructor(
    private restClient: RestClientService,
    private readonly cookieService: CookieService
  ) { }

  async fetchAndSavePublicKey(): Promise<void | null> {
    const keyData = await lastValueFrom(
      this.restClient.get<{
        status: {}
        hasError: boolean
        modulus: string
        exponent: string
      }>(`user/getPublicKey`).pipe(
        map((res) => res),
        catchError((error) => {
          return of({
            status: {
              code: error.status,
              message: error.statusText
            },
            hasError: true,
            modulus: '',
            exponent: ''
          })
        })
      )
    );

    if(!keyData.hasError) {
      this.modulus = keyData.modulus;
      this.exponent = keyData.exponent;
    }
  }

  getModulus(): string | null {
    return this.modulus;
  }
  getExponent(): string | null {
    return this.exponent;
  }

  getLoginKey(): string | undefined {
    return this.cookieService.get(CookieNameList.LOGIN_KEY)
  }

  saveLoginKey(randomKey: string) {
    this.cookieService.set(CookieNameList.LOGIN_KEY, randomKey);
  }
}
