import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import dayjs from 'dayjs/esm';
import {SessionService} from "../../../../services/session/session.service";
import {UserService} from "../../../../services/user/user.service";
import {AuthenticationService} from "../../../../services/authentication/authentication.service";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent implements OnInit {

  isLoading?: boolean;
  idleState = "NOT_STARTED";
  countdown?: number | null = null;
  lastPing?: Date | null = null;

  constructor(
    private router: Router,
    private idle: Idle,
    cd: ChangeDetectorRef,
    private readonly sessionService: SessionService,
    private readonly authenticationService: AuthenticationService,
    private readonly toastService: ToastrService,
  ) {

    // Spinner for lazyload modules
    router.events.forEach((event) => {
      if (event instanceof RouteConfigLoadStart) {
        this.isLoading = true;
      } else if (event instanceof RouteConfigLoadEnd) {
        this.isLoading = false;
      }
    });

    idle.setIdle(121); // how long can they be inactive before considered idle, in seconds
    idle.setTimeout(600); // how long can they be idle before considered timed out, in seconds
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);


  }

  reset() {
    // we'll call this method when we want to start/reset the idle process
    // reset any component state and be sure to call idle.watch()
    this.idle.watch();
    this.idleState = "NOT_IDLE";
    this.countdown = null;
    this.lastPing = null;
  }

  ngOnInit(): void {
    this.reset();
    this.sessionService.startSessionCheck()
    this.idle.onIdleStart.subscribe(() => {
      this.idleState = "IDLE";
    });
    this.idle.onTimeout.subscribe(() => {
      this.idleState = "TIMED_OUT"
    });
    // do something as the timeout countdown does its thing
    this.idle.onTimeoutWarning.subscribe(seconds => {
      this.countdown = seconds
      const currentMinute = dayjs().minute()
      if (currentMinute === 15) location.reload()
      if(seconds === 1 || seconds === 0){
        this.authenticationService.logout().then(() => this.toastService.info("Vous avez été déconnecté pour cause d'inactivité."))
      }
    });
  }

}
