import {Component, DestroyRef, inject, OnInit, signal, WritableSignal} from '@angular/core';
import {Router} from '@angular/router';
import Cookies from "js-cookie";
import {ToastrService} from 'ngx-toastr';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {AuthenticationService} from 'src/app/services/authentication/authentication.service';
import {UserService} from 'src/app/services/user/user.service';
import {CookieNameList, CookieService} from "../../services/cookie/cookie.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {catchError} from "rxjs";
import {PublicKeyService} from "../../services/public-key/public-key.service";

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss']
})
export class AuthenticationComponent implements OnInit {
  private destroyRef = inject(DestroyRef);
  submitted = false;
  data: any = {
    username: '',
    password: '',
    isAd: false
  };
  loginInProgress: boolean = false;
  authenticationFailed: boolean = false
  authenticationText: WritableSignal<string> = signal('Se connecter')
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private publicKeyService: PublicKeyService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private userService: UserService,
    private readonly cookieService: CookieService,
    private toastr: ToastrService
  ) { }

  async ngOnInit(): Promise<void> {
    //Delete all old cookies
    this.cookieService.removeAllOld()
    if(this.userService.userIsLoggedIn()) {
      await this.router.navigate(['/app/dashboard'])
    }
  }

 onSubmit() {
    if (this.data.username.length === 0 || this.data.password.length === 0) {
      this.toastr.info('Veuillez renseigner tous les champs', 'Information');
      return;
    }
    this.loginInProgress = true
    this.authenticationFailed = false
    this.authenticationText.set('Connexion en cours...')
    this.authenticationService.login(this.data)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        next: (response) => {
          if (!response.hasError && response.hasOwnProperty('items') && (response.items as Array<any>).length > 0) {
            this.userService.setUser(response.items[0]);
            this.cookieService.set(CookieNameList.USER_SESSION_KEY, response.sessionUser);
            this.ngxUiLoaderService.startLoader('page')
            this.toastr.success('Authentification réussie !', 'Information');
            setTimeout(() => {
              this.router.navigateByUrl('/services').then(() => this.ngxUiLoaderService.stopLoader('page'))
            }, 2000);
            return;
          } else {
            this.toastr.error('Login/Mot de passe incorrect', 'Information');
            this.authenticationFailed = true
            this.authenticationText.set('Se connecter')
          }
        },
        error: (error: any) => {
          console.log('--- ERROR ---', error)
          this.authenticationText.set('Se connecter')
          this.toastr.error('Oops! Une erreur inattendue a été rencontrée.', 'Error');
          this.loginInProgress = false
          this.publicKeyService.fetchAndSavePublicKey()
          throw new Error(error)
        },
        complete: () => {
          this.loginInProgress = false
        }
      })
  }

}
