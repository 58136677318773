import { Component, OnInit } from '@angular/core';
import {RouterLink} from "@angular/router";

@Component({
  standalone: true,
  selector: 'app-not-found-errors',
  templateUrl: './not-found.component.html',
  styleUrls: ['./errors.component.scss'],
  imports: [
    RouterLink
  ]
})
export class PageNotFoundComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

}
