import {AfterViewInit, Component, OnInit} from '@angular/core';
import { Observable, Subject, filter, fromEvent, takeUntil, tap } from 'rxjs';
import { UserService } from './services/user/user.service';
import { NavigationStart, Router, RouterEvent } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'OmerOBF';

  private destroyed$ = new Subject();

  onlineEvent$!: Observable<Event>;
  offlineEvent$!: Observable<Event>;

  isOnline$!: Observable<any>;
  isOffline$!: Observable<any>;

  onlineStatusMessage!: string;
  onlineStatus!: string;
  isVisible: boolean = true

  constructor(
    private userService: UserService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.onlineEvent$ = fromEvent(window, 'online');
    this.offlineEvent$ = fromEvent(window, 'offline');

    this.isOnline$ = this.onlineEvent$.pipe(
      tap(e => {
        this.isVisible = true
        this.onlineStatus = e.type;
        this.onlineStatusMessage = 'Vous êtes de retour en ligne !';
        setTimeout(() => {
          this.isVisible = false
        }, 5000);
      })
    )

    this.isOffline$ = this.offlineEvent$.pipe(
      tap(e => {
        this.isVisible = true
        this.onlineStatus = e.type;
        this.onlineStatusMessage = "Vous n'êtes pas connecté à Internet. Vérifiez votre connexion.";
        setTimeout(() => {
          this.isVisible = false
        }, 5000);
      })
    )

    this.router.events
      .pipe(
        filter((event: any) => event instanceof NavigationStart),
        takeUntil(this.destroyed$),
      )
      .subscribe((event: NavigationStart) => {
        // handle navigation start event
        //Load User Permissions
        this.userService.loadUserPermissions();
      });
  }

}
