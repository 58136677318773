<!-- As a link -->
<div class="univers-component">
    <header class="univers-header">
        <nav class="navbar navbar-dark bg-white">
          <div class="container">
            <div class="navbar-brand me-auto">
              <a class="stretched-link app-logo" href="#">
                <img class="logo-img" src="assets/images/logos/om-analytic.png" loading="lazy" alt="Logo OM Analytic">
              </a>
            </div>
            <div class="logout-button align-self-center">
                <button type="button" class="btn btn-primary text-uppercase" (click)="logout($event)">Déconnexion</button>
            </div>
          </div>
        </nav>
    </header>
    <main class="main-content">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <h1 class="text-uppercase welcome-text"><strong>Bienvenue</strong><span class="text-primary username"> {{ userFullName }}</span> </h1>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="univers-listing row">
                        <ng-container *ngFor="let item of univers; let index = index;">
                            <div class="item col-12 col-md-3" (click)="goToUnivers(index)">
                                <div class="card univers-card">
                                    <div class="card-body text-center">
                                        <img src="assets/images/{{item.image}}" alt="">
                                        <span class="d-block univers-name">
                                            {{ item.name }}
                                        </span>
                                        <small *ngIf="item.name === 'Air Time'">Pas encore disponible</small>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="mt-4">
                <div class="row">
                    <div class="col-md-12">
                        <p>*Veuillez sélectionner un service pour accéder à la plateforme</p>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>

