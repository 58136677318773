import {inject, Injectable} from '@angular/core';
import {Observable, Subject, throwError} from 'rxjs';
import {RestClientService} from '../rest-client.service';
import {NgxPermissionsService} from 'ngx-permissions';
import {Router} from "@angular/router";
import {CookieNameList, CookieService} from "../cookie/cookie.service";
import {CryptoService} from "../crypto/crypto.service";
import {PublicKeyService} from "../public-key/public-key.service";
import {UtilitiesService} from "../utilities/utilities.service";
import {PermissionService} from "../permission/permission.service";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private readonly basePath: string = 'user'
  private authenticatedUser = new Subject<any>()
  constructor(
    private restClient: RestClientService,
    private readonly cookieService: CookieService,
    private permissionService: PermissionService,
    private readonly cryptoService: CryptoService,
    private readonly publicKeyService: PublicKeyService,
    private readonly utilitiesService: UtilitiesService,
    private router: Router
  ) { }

   login(datas: any): Observable<any> {
    this.publicKeyService.saveLoginKey(this.utilitiesService.generateRandomString(36))
    const bodyDatas = {
      isAd: datas.isAd,
      key: this.publicKeyService.getLoginKey(),
      data: {
        login: datas.username,
        password: datas.password,
      }
    }
    const modulus = this.publicKeyService.getModulus()
    const exponent = this.publicKeyService.getExponent()
    if(!modulus || !exponent){
      return throwError(() => {
        return new Error('Désolé, nous rencontrons un problème')
      })
    }

    const encryptedLoginData = {
      data: this.cryptoService.encryptDataWithRSA(JSON.stringify(bodyDatas), modulus, exponent)
    }

    return this.restClient.post(`${this.basePath}/login`, bodyDatas)

  }

  setAuthenticatedData(datas: any) {

    //this.authenticatedUser.next(datas)
  }

  /**
   * Logout
   */
  async logout() {
    this.cookieService.removeAll()
    this.permissionService.removeAll()
    if (!this.cookieService.get(CookieNameList.USER_INFO)) {
      await this.router.navigate(['']);
    }
  }




}
