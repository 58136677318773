<div class="not-found-page">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="inner-content py-4">
          <h1 class="page-title text-primary">Ooops !</h1>
          <p class="description">La page recherché n'a pas été trouvée</p>
          <img src="assets/images/404.svg" class="img-fluid" alt="Page non trouvée">
          <div class="mt-3">
            <a class="btn btn-primary" routerLink="/">Revenir à l'accueil</a>&nbsp;
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
