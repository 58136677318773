import {inject, Injectable} from '@angular/core';
import {NgxPermissionsService} from "ngx-permissions";

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  private permissions: string[] = [];
  private readonly ngxPermissionService = inject(NgxPermissionsService)
  constructor() { }

  setPermissions(permissions: string[]): void {
    this.permissions = permissions;
    sessionStorage.setItem('omer-permissions', JSON.stringify(permissions));
  }

  getPermissions(): string[] {
    const permissions = sessionStorage.getItem('omer-permissions')
    if (!permissions) return [];
    return JSON.parse(permissions);
    // return this.permissions;
  }

  hasPermission(permission: string): boolean {
    return this.permissions.includes(permission);
  }

  removeAll(){
    this.setPermissions([]);
    this.ngxPermissionService.flushPermissions()
  }

  loadUserPermissions() {
    if (this.getPermissions().length > 0) {
        this.ngxPermissionService.loadPermissions(this.getPermissions());
    }
  }

  fetchPermissionByProfile(profilCode: string){
    console.log('--- fetchPermissionByProfile ----', profilCode)
  }
}
