import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BaseComponent } from './views/authenticated/layout/base/base.component';
import { AuthenticationComponent } from './views/authentication/authentication.component';
import { PageNotFoundComponent } from './views/errors/not-found.component';
import { UniversComponent } from './views/authenticated/pages/univers/univers.component';
import {
  ngxPermissionsGuard
} from 'ngx-permissions';
import {DashboardComponent} from "./features/dashboard/dashboard.component";
import {LocaliteComponent} from "./features/reseau/localite/localite.component";
import {ActionHistoryComponent} from "./features/action-history/action-history.component";

const routes: Routes = [
  {
    path: '',
    component: AuthenticationComponent
  },
  {
    path: 'services',
    canActivate: [ngxPermissionsGuard],
    component: UniversComponent,
    data: {
      permissions: {
        only: ['VIEW_OM', 'VIEW_AIRTIME'],
        redirectTo: '/'
      }
    }
  },
  {
    path: 'app',
    component: BaseComponent,
    children: [
      {
        path: 'dashboard-old',
        canActivate: [ngxPermissionsGuard],
        data: {
          permissions: {
            only: ['VIEW_DASHBOARD'],
            redirectTo: '/'
          }
        },
        loadChildren: () => import("./views/authenticated/pages/dashboard/dashboard.module").then(m => m.DashboardModule)
      },
      {
        path: 'dashboard',
        canActivate: [ngxPermissionsGuard],
        data: {
          permissions: {
            only: ['VIEW_DASHBOARD'],
            redirectTo: '/'
          }
        },
        loadComponent: () => import("./features/dashboard/dashboard.component").then(m => m.DashboardComponent)
      },
      {
        path: 'uv-stock-level',
        loadChildren: () => import("./views/authenticated/pages/stock-level/stock-level.module").then(m => m.StockLevelModule)
      },
      {
        path: 'taux-rupture',
        canActivate: [ngxPermissionsGuard],
        data: {
          permissions: {
            only: ['VIEW_PAGE_TAUX_RUPTURE'],
            redirectTo: '/'
          }
        },
        loadChildren: () => import("./views/authenticated/pages/taux-rupture/taux-rupture.module").then(m => m.TauxRuptureModule)
      },
      {
        path: 'pdv-rupture',
        canActivate: [ngxPermissionsGuard],
        data: {
          permissions: {
            only: ['VIEW_PAGE_PDV_RUPTURE'],
            redirectTo: '/'
          }
        },
        loadChildren: () => import("./views/authenticated/pages/rupture-pdv/rupture-pdv.module").then(m => m.RupturePdvModule)
      },
      {
        path: 'prevision-pdv-rupture',
        canActivate: [ngxPermissionsGuard],
        data: {
          permissions: {
            only: ['VIEW_PAGE_PREVISION_PDV_RUPTURE'],
            redirectTo: '/'
          }
        },
        loadChildren: () => import("./views/authenticated/pages/prevision-rupture-pdv/prevision-rupture-pdv.module").then(m => m.PrevisionRupturePdvModule)
      },
      {
        path: 'niveau-maj',
        canActivate: [ngxPermissionsGuard],
        data: {
          permissions: {
            only: ['VIEW_PAGE_NIVEAU_MAJ'],
            redirectTo: '/'
          }
        },
        loadChildren: () => import("./views/authenticated/pages/niveau-maj/niveau-maj.module").then(m => m.NiveauMajModule)
      },
      {
        path: 'reseau/zones',
        canActivate: [ngxPermissionsGuard],
        data: {
          permissions: {
            only: ['VIEW_PAGE_REGION'],
            redirectTo: '/'
          }
        },
        loadChildren: () => import("./views/authenticated/pages/reseau/zone/zone.module").then(m => m.ZoneModule)
      },
      {
        path: 'reseau/territoires',
        canActivate: [ngxPermissionsGuard],
        data: {
          permissions: {
            only: ['VIEW_PAGE_TERRITOIRE'],
            redirectTo: '/'
          }
        },
        loadChildren: () => import("./views/authenticated/pages/reseau/territoire/territoire.module").then(m => m.TerritoireModule)
      },
      {
        path: 'reseau/distributeurs',
        canActivate: [ngxPermissionsGuard],
        data: {
          permissions: {
            only: ['VIEW_PAGE_DISTRIBUTEUR'],
            redirectTo: '/'
          }
        },
        loadChildren: () => import("./views/authenticated/pages/reseau/distributeur/distributeur.module").then(m => m.DistributeurModule)
      },
      {
        path: 'reseau/localites',
        canActivate: [ngxPermissionsGuard],
        data: {
          permissions: {
            only: ['VIEW_PAGE_LOCALITE'],
            redirectTo: '/'
          }
        },
        loadComponent: () => import("./features/reseau/localite/localite.component").then(m => m.LocaliteComponent)
      },
      {
        path: 'configuration/utilisateurs',
        canActivate: [ngxPermissionsGuard],
        data: {
          permissions: {
            only: ['VIEW_PAGE_USER'],
            redirectTo: '/'
          }
        },
        loadChildren: () => import("./views/authenticated/pages/utilisateur/utilisateur.module").then(m => m.UtilisateurModule)
      },
      {
        path: 'report/action-history',
        canActivate: [ngxPermissionsGuard],
        data: {
          permissions: {
            only: ['VIEW_PAGE_ACTION_HISTORY'],
            redirectTo: '/'
          }
        },
        loadComponent: () => import("./features/action-history/action-history.component").then(m => m.ActionHistoryComponent)
      },
      {
        path: 'configuration/profils',
        canActivate: [ngxPermissionsGuard],
        data: {
          permissions: {
            only: ['VIEW_PAGE_PROFIL'],
            redirectTo: '/'
          }
        },
        loadChildren: () => import("./views/authenticated/pages/profil/profil.module").then(m => m.ProfilModule)
      },
      {
        path: 'configuration/fonctionnalites',
        canActivate: [ngxPermissionsGuard],
        data: {
          permissions: {
            only: ['VIEW_PAGE_PERMISSION'],
            redirectTo: '/'
          }
        },
        loadChildren: () => import("./views/authenticated/pages/fonctionnalite/fonctionnalite.module").then(m => m.FonctionnaliteModule)
      },
      {
        path: 'enregistrement-base',
        loadChildren: () => import("./views/authenticated/pages/enregistrement-base/enregistrement-base.module").then(m => m.EnregistrementBaseModule)
      },
      {
        path: 'parametre',
        canActivate: [ngxPermissionsGuard],
        data: {
          permissions: {
            only: ['VIEW_PAGE_PARAMETRAGE'],
            redirectTo: '/'
          }
        },
        loadChildren: () => import("./views/authenticated/pages/settings/settings.module").then(m => m.SettingsModule)
      },
      {
        path: 'configuration/seuil',
        canActivate: [ngxPermissionsGuard],
        data: {
          permissions: {
            only: ['VIEW_PAGE_SEUIL'],
            redirectTo: '/'
          }
        },
        loadChildren: () => import("./views/authenticated/pages/seuil/seuil.module").then(m => m.SeuilModule)
      },
      {
        path: 'configuration/alerte',
        canActivate: [ngxPermissionsGuard],
        data: {
          permissions: {
            only: ['VIEW_PAGE_ALERTE'],
            redirectTo: '/'
          }
        },
        loadChildren: () => import("./views/authenticated/pages/alerte/alerte.module").then(m => m.AlerteModule)
      },
      {
        path: 'geolocatisation-pdv',
        canActivate: [ngxPermissionsGuard],
        data: {
          permissions: {
            only: ['VIEW_PAGE_PDV_GEOLOCATION'],
            redirectTo: '/'
          }
        },
        loadComponent: () => import("./features/pdv-geolocation/pdv-geolocation.component").then(m => m.PdvGeolocationComponent)
      },
      {
        path: 'approvisionnement-uv',
        canActivate: [ngxPermissionsGuard],
        data: {
          permissions: {
            only: ['VIEW_PAGE_APPROVISIONNEMENT_UV'],
            redirectTo: '/'
          }
        },
        loadComponent: () => import("./features/approvisionnement-uv/approvisionnement-uv.component").then(m => m.ApprovisionnementUvComponent)
      },
      {
        path: 'new-agent',
        canActivate: [ngxPermissionsGuard],
        data: {
          permissions: {
            only: ['VIEW_PAGE_NEW_AGENT'],
            redirectTo: '/'
          }
        },
        loadComponent: () => import("./features/nouveau-agent/nouveau-agent.component").then(m => m.NouveauAgentComponent)
      },
      {
        path: 'best-agent',
        canActivate: [ngxPermissionsGuard],
        data: {
          permissions: {
            only: ['VIEW_PAGE_BEST_AGENT'],
            redirectTo: '/'
          }
        },
        loadComponent: () => import("./features/meilleur-agent/meilleur-agent.component").then(m => m.MeilleurAgentComponent)
      },
      {
        path: 'agent-segment',
        canActivate: [ngxPermissionsGuard],
        data: {
          permissions: {
            only: ['VIEW_PAGE_AGENT_SEGMENT'],
            redirectTo: '/'
          }
        },
        loadComponent: () => import("./features/segment-agent/segment-agent.component").then(m => m.SegmentAgentComponent)
      },
      {
        path: 'agent-inactivity',
        canActivate: [ngxPermissionsGuard],
        data: {
          permissions: {
            only: ['VIEW_PAGE_AGENT_INACTIVITY'],
            redirectTo: '/'
          }
        },
        loadComponent: () => import("./features/inactivite-agent/inactivite-agent.component").then(m => m.InactiviteAgentComponent)
      },
      {
        path: 'commissions',
        canActivate: [ngxPermissionsGuard],
        data: {
          permissions: {
            only: ['VIEW_PAGE_COMMISSION'],
            redirectTo: '/'
          }
        },
        loadComponent: () => import("./features/commissions/commissions.component").then(m => m.CommissionsComponent)
      },
      {
        path: '**',
        redirectTo: '/'
      },
    ]
  },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
