import {HttpInterceptorFn} from '@angular/common/http';
import Cookies from "js-cookie";
import {UtilitiesService} from "../services/utilities/utilities.service";
import {inject, PLATFORM_ID} from "@angular/core";
import {UserService} from "../services/user/user.service";
import {CookieNameList, CookieService} from "../services/cookie/cookie.service";
import {isPlatformBrowser} from "@angular/common";
import {PublicKeyService} from "../services/public-key/public-key.service";
import {throwError} from "rxjs";
import {CryptoService} from "../services/crypto/crypto.service";

export const authenticationInterceptor: HttpInterceptorFn = (req, next) => {
  const sessionUser = inject(CookieService).get(CookieNameList.USER_SESSION_KEY);
  const userMatricule = inject(UserService).userMatricule
  const plateformId = inject(PLATFORM_ID)
  const getMachineName = () => {
    return isPlatformBrowser(plateformId) ? window.location.hostname : 'Host non disponible'
  }
  let requestClone = req.clone()
  requestClone = requestClone.clone({
    setHeaders: {
      user: userMatricule
    }
  })
  if (sessionUser) {
    requestClone = requestClone.clone({
      setHeaders: {
        sessionUser
      }
    })
  }

  if(req.method !== 'GET' && typeof req.body === 'object') {

    if(req.body instanceof FormData){
      const formData = new FormData();
      req.body.forEach((value: any, key: string) => {
        formData.append(key, value);
      });
      formData.append('user', userMatricule);
      formData.append('hostName', getMachineName());
      requestClone = requestClone.clone({
        body: formData
      })
    } else {
      const modifiedBody = {
        ...req.body,
        user: userMatricule,
        hostName: getMachineName()
      };
      requestClone = requestClone.clone({
        body: modifiedBody
      })
    }

    return next(requestClone);
  }
  return next(requestClone);
};
